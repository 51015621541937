import React, { useEffect, useState } from 'react';
import axios from 'axios';      
import { Link} from 'react-router-dom';

import {Button,Checkbox} from '../../components'
import CreateAssessmentType from '../assessmentType/CreateAssessmentType';
import EditQuestionModule from './EditQuestionModule';

const QuestionBank = ({questionSheetId,assessmentNames,assessmentNamesLoading}) => {
    const [tab, setTab] = useState()
    const [questions, setQuestions] = useState()

    const [selectMultiple, setSelectMultiple] = useState(false)
    const [selectedQuestions, setSelectedQuestions] = useState([])

    const [updateQuestions,setUpdateQuestions] = useState(0)

    const [loading, setLoading] = useState(false)
    const [successModuleOpen, setSuccessModuleOpen] = useState(false)
    const [createAssessmentModule,setCreateAssessmentModule ] = useState()
    useEffect(() => setCreateAssessmentModule(document.getElementById('createAssessmentModule')),[])
    const [editQuestionModule,setEditQuestionModule ] = useState()
    useEffect(() => setEditQuestionModule(document.getElementById('editQuestionModule')),[])
    const [deleteQusModule,setDeleteQusModule ] = useState()
    useEffect(() => setDeleteQusModule(document.getElementById('deleteQusModule')),[])

    const [questionLoading, setQuestionLoading] = useState(true)
    useEffect(()=>{
        setTab(assessmentNames && assessmentNames[0])
    },[assessmentNames])
    
    const handleChecked = (e,index) => {
        const { checked } = e.target;
        if (checked) {
            questions[index].value = true;           
        } else {
            questions[index].value = false;
        }
        const selectedQus = questions?.map((qus) => qus.value && qus?.num)?.filter(qus => qus);
        setSelectedQuestions(selectedQus)
    }

    useEffect(() => {
            if (assessmentNames && tab) {
                let fetchData = async() => {
                    setQuestionLoading(true)
                    const sheetName = tab
                    const questionData = JSON.stringify([questionSheetId,sheetName])
                    const response =  await axios.post(process.env.REACT_APP_FETCH_API,questionData);
                    const activeQuestions = response.data.allData.filter((q) => q.active !== -1)
                    setQuestions(activeQuestions)
                    setQuestionLoading(false)
                }
                fetchData()
            }
        
    }, [questionSheetId,tab,assessmentNames,updateQuestions])
    
    const deleteApi = process.env.REACT_APP_DELETE_QUESTIONS_AND_JOBS
    const [deleteId , setDeleteId] = useState();
    const deleteQuestion = async () => {
        setLoading(true)
        closeDeleteModule()
        setSuccessModuleOpen(true)
        const deleteData = JSON.stringify([questionSheetId, tab, [Number(deleteId)]])
        const response = await axios.post(deleteApi,deleteData)
        if(response.data.message === 'success'){
            setUpdateQuestions(prev => prev + 1 )
        }else{
            setSuccessModuleOpen(false)
        }
        setLoading(false)
    }

    const deleteMultiple = async () => {
        setLoading(true)
        closeDeleteModule()
        setSuccessModuleOpen(true)
        const deleteData = JSON.stringify([questionSheetId, tab, selectedQuestions])
        const response = await axios.post(deleteApi,deleteData)
        if(response.data.message === 'success'){
            setUpdateQuestions(prev => prev + 1 )
        }else{
            setSuccessModuleOpen(false)
        }
        setLoading(false)
    }

    const openDeleteModule = (e) =>{
        setDeleteId(e.target.id)
        deleteQusModule?.classList.remove('hidden')
        deleteQusModule?.classList.add('flex')
    }
    const closeDeleteModule = () => {
        deleteQusModule?.classList.remove('flex')
        deleteQusModule?.classList.add('hidden')
    }

    const showCreateAssessmentModule = () => {
        createAssessmentModule?.classList.remove('hidden')
        createAssessmentModule?.classList.add('flex')
    }
    const closeCreateAssessmentModule = () => {
        createAssessmentModule?.classList.remove('flex')
        createAssessmentModule?.classList.add('hidden')
    }

    const [question, setQuestion] = useState()
    const showEditQuestionModule = (e) => {
        const num = Number(e.target.id)
        const qus = questions?.filter((qus) => qus.num === num)
        setQuestion(qus[0])
        editQuestionModule?.classList.remove('hidden')
        editQuestionModule?.classList.add('flex')
    }
    const closeEditQuestionModule = () => {
        editQuestionModule?.classList.remove('flex')
        editQuestionModule?.classList.add('hidden')
    }
   
    const [showCodeId, setShowCodeId] = useState()
    const expandCode = (e) => {
        e.target.id === showCodeId ? setShowCodeId("") : setShowCodeId(e.target.id)
    }

    return (
    <div className="main-container question-bank-wrapper relative lg:h-[91vh] mx-auto md:ml-20 mt-2 flex flex-col gap-3 lg-px-10 overflow-hidden">
         <div id='createAssessmentModule' className='hidden z-10 h-full w-full absolute top-0 left-0 bg-[#000]/10 backdrop-blur-[4px] justify-center items-center'>
            <CreateAssessmentType
                questionSheetId={questionSheetId}
                closeCreateAssessmentModule={closeCreateAssessmentModule}
            />
        </div>
        <div id='editQuestionModule' className='hidden z-10 h-full w-full absolute top-0 left-0 bg-[#000]/10 backdrop-blur-[4px] justify-center items-center'>
            <EditQuestionModule
                question={question}
                questionSheetId={questionSheetId}
                assessmentType = {tab}
                closeEditQuestionModule={closeEditQuestionModule}
                setUpdateQuestions={setUpdateQuestions}
            />
        </div>
        <div id='deleteQusModule' className='hidden z-10 h-full w-full absolute top-0 left-0 bg-[#000]/10 backdrop-blur-[4px] justify-center items-center'>
            <div className='common-popup card p-10 flex flex-col justify-center items-center w-[95%]'>
			    <p className='font-semibold text-xl mb-5 w-[20rem] text-center'>
                    Do you really want to delete {selectedQuestions.length === 0 ? 'this question' : 'the selected questions'} ? 
                </p>
                <div className='flex mt-8 gap-5'>
                    <Button
                        text="Delete"
                        version="red"
                        width='8rem'
                        handleClick={selectedQuestions.length === 0 ? deleteQuestion :deleteMultiple}
                    />
                    <Button
                        text="Cancel"
                        version='secondary'
                        width='8rem'
                        handleClick={closeDeleteModule}
                    />
                </div>                
            </div>
        </div>
        {/*success module */}
        <div className={`${successModuleOpen ? 'flex' : 'hidden'} z-10 absolute top-0 left-0 justify-center items-center w-full h-full bg-[#000]/10 backdrop-blur-[2px]`}>
            <div className='common-popup card p-10 flex flex-col justify-center items-center gap-5 text-center'>
                {loading ? (
                    <div className='font-bold text-[#888] text-2xl'>
                        Loading ...
                    </div>
                ) : (
                    <>
                        <div className='w-20 h-20 bg-[#57AD55]/80 rounded-full flex justify-center items-center'>
                            <p className='text-white text-4xl'>✓</p> 
                        </div>
                        <h1 className='text-lg'>The {selectMultiple ?  'questions were' : 'question was'} successfully deleted.</h1>
                            <Button
                                text=" OK "
                                width='6rem'
                                handleClick = {() => {
                                    setSuccessModuleOpen(false)
                                    setSelectMultiple(false)
                                }}
                            />
                    </>
                )}
                               
            </div>
        </div>

        {assessmentNamesLoading ? (
            <div className='card w-full h-full flex justify-center items-center'>
                <p className='text-2xl font-bold text-[#888]'>
                    Loading . . .
                </p>
            </div>
        ) : ( assessmentNames?.length === 0 ? (            
                <div className='card h-full w-full flex flex-col justify-center items-center gap-5'>
                    <h1 className='font-semibold text-2xl text-[#292D32]'>
                        Create Your First Assessment Type
                    </h1>
                    <p className='w-2/5 text-center text-sm text-[#666]'>
                        Oops! It looks like you haven't created an assessment type yet. Please create an assessment type to get started.
                    </p>
                        <Button
                            handleClick={showCreateAssessmentModule}
                            width='16rem'
                            text='Create Assessment Type'
                        />
                </div>
            ) : (
            <>
                <div className='tab-wrapper w-full card flex items-center gap-3 px-5 py-3 overflow-x-auto overflow-y-hidden scrollbar-none'>
                    {assessmentNames?.map((tabName)=>{
                        return (
                            <div
                                key={tabName}
                                id={`tab-${tabName}`}
                                className={`tab-btn capitalize text-sm rounded-full px-8 py-2 cursor-pointer ${tab === tabName ? 'active' : 'bg-[#F8F8F8]'}`}
                                onClick= {() => {
                                    setTab(tabName)
                                    setSelectMultiple(false)
                                }}
                            >
                                {tabName}
                            </div>
                        )
                    })}
                </div>
                <div className='content-wrapper w-ful card h-full px-4 sm:px-8 pt-4 overflow-hidden'>
                    <div className=''>
                        {questionLoading ? (
                            <div className='w-full flex flex-col md:flex-row md:items-center md:justify-between'>
                                <div className='bg-[#aaa]/10 w-[20rem] h-[2.5rem] mt-4 rounded-2xl'/>
                                <div className='w-full md:w-fit flex sm:flex-row flex-col-reverse sm:justify-between gap-2 mt-2'>
                                    <div className='bg-[#aaa]/10 w-full sm:w-[12rem] h-[2.5rem] mt-4 rounded-2xl'/>
                                    <div className='bg-[#aaa]/10 w-full sm:w-[12rem] h-[2.5rem] mt-4 rounded-2xl'/>
                                </div>                                
                            </div>
                        ) : (
                            <div className='w-full flex flex-col md:flex-row md:items-center md:justify-between'>
                                <div className='text-lg capitalize font-semibold mt-4'>{tab} Questions</div>
                                {selectMultiple ? (
                                    <div className='w-full md:w-fit flex sm:flex-row flex-col gap-2 mt-2'>
                                        <Button
                                            version="red"
                                            text="Delete Selected"
                                            width='10rem'
                                            isDisabled={selectedQuestions.length === 0}
                                            handleClick={openDeleteModule}
                                        />
                                        <Button
                                            version='secondary'
                                            text="Cancel"
                                            handleClick={() => {
                                                setSelectMultiple(false)
                                                setSelectedQuestions([])
                                            }}
                                        />
                                    </div>
                                        
                                ) : (
                                    questions?.length !== 0 &&
                                    <div className='w-full md:w-fit flex sm:flex-row flex-col-reverse sm:justify-between gap-2 mt-2'>
                                        <Button
                                            version="outline-primary"
                                            text="Select Multiple"
                                            handleClick={() => setSelectMultiple(true)}
                                        />
                                        <Link to='/quesUpload'>
                                            <Button
                                                width='100%'
                                                text='Add New Questions'
                                            />
                                        </Link>     
                                    </div>                                        
                                )}                              
                                    
                            </div>
                        )}
                    </div>
                    {questionLoading ? (
                        <div className='flex flex-col gap-3'>
                            <div className='bg-[#aaa]/10 w-full h-[8rem] mt-4 rounded-2xl'/>
                            <div className='bg-[#bbb]/10 w-full h-[7rem] mt-4 rounded-2xl'/>
                            <div className='bg-[#ccc]/10 w-full h-[6rem] mt-4 rounded-2xl'/>
                            <div className='bg-[#ddd]/10 w-full h-[5rem] mt-4 rounded-2xl'/>
                        </div>
                    ) : (
                        <div className='h-full my-4 overflow-y-scroll scrollable flex flex-col gap-3 pr-2'>
                            {questions?.length === 0 ? (
                                <div className='h-full w-full flex flex-col justify-center items-center gap-5'>
                                    <h1 className='font-semibold text-2xl text-[#292D32]'>
                                        Add Questions to Your Assessment Type
                                    </h1>
                                    <p className='w-2/5 text-center text-sm text-[#666]'>
                                        Currently, there are no questions for this assessment type. Please add questions to create an assessment.
                                    </p>
                                    <Link to='/quesUpload'>
                                        <Button
                                            width='16rem'
                                            text='Add Questions'
                                        />
                                    </Link>
                                </div>
                            ) : (
                                questions
                                    ?.filter((qus) => qus.active !== -1)
                                    ?.map((qus,index) => {
                                    return (
                                        <div key={qus.num} className='relative border-[1px] py-3 sm:px-6 border-[#C4C4C4]/40 rounded-2xl flex gap-2 shadow-md'>
                                           { selectMultiple && 
                                                (<div key={qus.num} className='h-full flex items-center ml-3 sm:m-0'>
                                                    <input
                                                        type="checkbox"
                                                        className="w-[25px] h-[25px] rounded-md focus:ring-0 border-[2.5px] border-[#8A24F1] checked:bg-[#8A24F1] checked:border-[#8A24F1]"
                                                        name="selectQuestion"
                                                        value={qus.num}
                                                        onChange={(a) => {
                                                            handleChecked(a, index)
                                                        }}
                                                    /> 
                                                    {/* <Checkbox
                                                        checkName="selectQuestion"
                                                        checkValue={qus.num}
                                                        handleChange={(a) => {
                                                            handleChecked(a, index)
                                                        }}
                                                    /> */}
                                                </div>)
                                            }
                                            {!selectMultiple && (
                                                <div className='absolute flex gap-3 sm:gap-6 right-6'>
                                                    <div className='relative'>
                                                        <div
                                                            id={qus.num}
                                                            onClick={(e) => showEditQuestionModule(e)}
                                                            className='absolute top-0 left-0 w-full h-full cursor-pointer'
                                                        />
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 text-[#666]">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                        </svg>
                                                    </div>
                                                    <div className='relative'>
                                                        <div
                                                            id={qus.num}
                                                            onClick={(e) => openDeleteModule(e)}
                                                            className='absolute top-0 left-0 w-full h-full cursor-pointer'
                                                        />
                                                        <svg                                                
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={1.5}
                                                            stroke="currentColor"
                                                            className="w-5 h-5 text-[#f00]"
                                                        >
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                        </svg>
                                                    </div>                                        
                                                </div>
                                            )}                                           

                                            <div className='w-full flex flex-col px-4 gap-2 ques-card'>
                                                <div className='font-medium text-sm heading'>Question {index + 1}</div>
                                                <div className='text-sm para'>
                                                    {qus?.questionStatment.split('/n').map(element => (
                                                        <pre>
                                                            {element.split('<code>')[0]}
                                                            {element.split('<code>')[1] &&
                                                                <div className='code-box w-full lg:w-2/3 border-2 p-2 m-2 mx-4 px-4 rounded-lg'>
                                                                    <div className='flex justify-between'>
                                                                        <p className='font-semibold'>Code - </p>
                                                                        <p id={qus.num} className='cursor-pointer text-blue-400' onClick={(e) => expandCode(e)}>{Number(showCodeId) === qus.num ? 'hide' : 'show'}</p>
                                                                    </div>
                                                                    <pre className={`${Number(showCodeId) === Number(qus?.num) ? '' : 'hidden'}`}>{element.split('<code>')[1]}</pre>                                                                    
                                                                </div>
                                                            }
                                                        </pre>                                                        
                                                    ))}
                                                </div>
                                                <div className='w-full pre-card lg:w-4/5 text-xs flex flex-col gap-2'>
                                                    <div className ='pre-small-card flex flex-col sm:flex-row gap-2 sm:items-center justify-between'>
                                                        {
                                                            ['A','B'].map((i)=>{
                                                                return (
                                                                    <div key={i} className={`option-box w-full mr-10 px-4 py-1 rounded-md flex items-center justify-between ${qus?.answer === i && 'correct-option'}`}>
                                                                        <div className='flex gap-2'>
                                                                            <p className='font-semibold'>{i}.</p> 
                                                                            <pre>{qus['option'+i]}</pre>
                                                                        </div>
                                                                        <div className='text'>{qus?.answer === i && 'correct'}</div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className='pre-small-card flex flex-col sm:flex-row gap-2 sm:items-center justify-between'>
                                                        {
                                                            ['C','D'].map((i)=>{
                                                                return (
                                                                    <div key={i} className={`option-box w-full mr-10 px-4 py-1 rounded-md flex items-center justify-between ${qus?.answer === i && 'correct-option'}`}>
                                                                        <div className='flex gap-2'>
                                                                            <p className='font-semibold'>{i}.</p> 
                                                                            <pre>{qus['option'+i]}</pre>
                                                                        </div>
                                                                        <div className='text'>{qus?.answer === i && 'correct'}</div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            )}
                        </div>
                    )}           
                </div>
            </>
            )
        )}
    </div>
  )
}

export default QuestionBank