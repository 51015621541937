import React,{useEffect, useState} from 'react'
import axios from 'axios';

import devtools from '../../../node_modules/devtools-detect/';
import emailBody from '../../emailBody';
import {Topbar2, ConfirmationModule,WarningModule} from '../../components'
import ExamTopPanel from './ExamTopPanel'
import ExamQuestionPanel from './ExamQuestionPanel'
import ExamOptionsPanel from './ExamOptionsPanel'
import SubmitModule from './SubmitModule';
import examFunctions from './examFunctions';
import Proctoring from './Proctoring';
import ExamLoadingModule from './ExamLoadingModule';

const ExamPage = () => {
  //states
  const [ examDetails, setExamDetails ] = useState([]);
  const [ startTime, setStartTime ] = useState();
	const [ startTimeLocal, setStartTimeLocal ] = useState();
	const [ questions, setQuestions ] = useState([]);
  const [ qusNum, setQusNum ] = useState(0);
  const [ answerArr,setAnswerArr ] = useState([]) 
	const [ timer, setTimer ] = useState(1)
  const [ timerPause, setTimerPause ] = useState(false)  
  const [ confirmationMessage, setConfirmationMessage ] = useState([]);
  const [ confirmationButton, setConfirmationButton ] = useState([]);
  const [ warningCount, setWarningCount ] = useState(0)
  const [ warningType, setWarningType ] = useState("")
  const [ warningMessage, setWarningMessage ] = useState([]);
  
  const [ violations, setViolations ] = useState("")
  const [ violationQuestion, setViolationQuestion ] = useState("")
  const [ questionArray,setQuestionArray] = useState()

  //dom elements
  let confirmationModule = document.getElementById("confirmationModule");
  let warningModule = document.getElementById("warningModule");
  let submitModule = document.getElementById("submitModule");

  //fetching examDetails from local storage
	useEffect(() => {
    const candidateDetails = JSON.parse(localStorage.getItem('candidateDetails'));
    if (candidateDetails) {
      setExamDetails(candidateDetails);
    }
  }, []);
  const examName = examDetails?.exam;
	const candidateName = examDetails?.name;
	const candidateEmail = examDetails?.emailId;
	const hrEmail = examDetails?.hrEmail;
  const uid = examDetails?.uid;
  const dataSheetId = examDetails?.dataSheetId;
  const questionSheetId = examDetails?.questionSheetId;

  let maxViolations
  if(examDetails?.maxViolations === "") {
    maxViolations = "NA"
  }else{
    maxViolations = Number(examDetails?.maxViolations);
  }

  const [ loading, setLoading ] = useState(true)
  const [ questionLoading, setQuestionLoading ] = useState(true)
  const [ proctorLoading, setProctorLoading ] = useState(true)
  useEffect(() => {
    !questionLoading && !proctorLoading && setLoading(false)
  },[questionLoading,proctorLoading])  

  //fetching Questions(from google sheets),
  useEffect(() => {
    if(questionSheetId && examName) {
      
      let fetchData = async() => {
        setQuestionLoading(true)
        // const maxQuestions = Number(examDetails?.maxQuestions)
        const fetchData = JSON.stringify([questionSheetId,examName])
        const response = await axios.post(process.env.REACT_APP_FETCH_QUESTIONS,fetchData);        

        if(response?.data.length > 0){
          const randomQuestionArray = []
          response?.data.map((qus) => (
            randomQuestionArray.push(qus.num)
          ))
          setQuestionArray(randomQuestionArray)
          setQuestions(response?.data)
          setTimer(response?.data[0]?.time)
        }
        setQuestionLoading(false)
      }
      fetchData()
    } 
}, [examName,questionSheetId,examDetails])

  //exam start time
	useEffect(() => {
		setStartTime(examFunctions.examTimeDate().time)
		setStartTimeLocal(examFunctions.examTimeDate().localTime)
	}, [])


  // Terminate on Refresh
  useEffect(() => {
    const isRefresh = localStorage.getItem('refresh')
    if(isRefresh){
      setWarningCount(warningCount+1)
      setTimerPause(true);
      setViolations(violations + 'Refreshed,')
      setViolationQuestion(violationQuestion + (qusNum+1) + ",")
      setViolationQuestion(prev => [...prev, qusNum+1])
      setWarningType("refresh")
      examFunctions.showModule(warningModule)
    }
  },[warningModule])
  const handleBeforeUnload = (e) => {
    e.preventDefault();
    localStorage.setItem('refresh',true)
  }
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
  },[])
 
  // hide warning Module
  const hideModule = () => {
    if(warningCount === maxViolations-1 ){
      setWarningMessage([`This is your last warning`,
        'If you violate the assessment rules again, Your assessment will be terminated.']);
      setWarningType("lastWarning")
    }else{
      examFunctions.handleFullscreen();
      examFunctions.hideModule(warningModule);
      setcellPhoneDetection(0)
      setTimerPause(false);
    }    
    setListning(true);
    setCameraDetection(true)
  } 

  // hide last warning message 
  const hideLastWarning = () => {
    examFunctions.handleFullscreen();
    examFunctions.hideModule(warningModule);
    setTimerPause(false);
  }

  // Tab switching  
  const [tabSwitch, setTabSwitch] = useState(false)
  useEffect(()=>{
      document.addEventListener('visibilitychange', () => {
      if (document.visibilityState !== 'visible') {
        setTabSwitch(true)
      }else{ 
        if (tabSwitch){
          setTimerPause(true)
          setViolations(violations + 'Tab/Window switched,')
          setViolationQuestion(violationQuestion + (qusNum+1) + ",")
          setWarningCount(warningCount + 1)
          setWarningMessage([`Tab/Window Switching is not allowed`,
            'Do not switch Tab/Window or your assessment will be terminated']);
          setWarningType("tabSwitch")
          setCameraDetection(false);
          setListning(false);
          examFunctions.showModule(warningModule)
        }
        setTabSwitch(false)
      }
    });
  })


  // full screen detection
  const fullScreenCheck = () => {
    if (!document.fullscreenElement) {
      setTimerPause(true);
      setViolations(violations + 'Exit fullscreen mode,')
      setViolationQuestion(violationQuestion + (qusNum+1) + ",")
      setWarningCount(warningCount + 1)
      setWarningMessage([`Do not exit fullscreen mode`,
        'else your assessment will be terminated']);
      setWarningType("fullscreen")
      examFunctions.showModule(warningModule)
      setCameraDetection(false);
      setListning(false);
    }
  }
  useEffect(()=>{
    // fullScreen check for Chrome,Edge,Safari
    document.addEventListener('webkitfullscreenchange', () => fullScreenCheck());
    // fullscreem check for mozilla Firefox
    document.addEventListener('mozfullscreenchange', () => fullScreenCheck());
  })

  //dev tools check
  useEffect(() => {
    window.addEventListener('devtoolschange', e => {
      if(e.detail.isOpen){
        setTimerPause(true);
        setViolations(violations + 'Open Devtools,')
        setViolationQuestion(violationQuestion + (qusNum+1) + ",")
        setWarningCount(warningCount + 1)
        setWarningMessage([`Do not open Inspect-tools.`,
          'Please close the dev tools, else the Assessment will be terminated.']);
        setWarningType("devtools")
        setCameraDetection(false);
        setListning(false);
        examFunctions.showModule(warningModule)
      }else{
        setWarningType("devtools-close")
      }
    });
  })

   
  useEffect(()=>{    
    // Prevent Ctrl+S, Ctrl+C & Ctrl+V
    document.onkeydown =  e => {
      e = e || window.event;
      if (e.ctrlKey) {
        let c = e.code;
        // console.log(e)
        if (['ShiftLeft','ShiftRight','KeyS', 'KeyC', 'KeyX', 'KeyV'].includes(c)) {
          e.preventDefault();
          e.stopPropagation();
        }
      };
    }

    // Prevent Screenshot
    document.onkeyup = e => {
      if(e.code  === 'PrintScreen'){
        e.preventDefault();
        e.stopPropagation();
        setTimerPause(true);
        setViolations(violations + 'Screenshot,')
        setViolationQuestion(violationQuestion + (qusNum+1) + ",")
        setWarningCount(warningCount + 1)
        setWarningMessage([`Screenshot not allowed.`,
            'Please dont try to take screenshots, else the Assessment will be terminated.']);
        setWarningType("screenShot")
        setCameraDetection(false);
        setListning(false);
          examFunctions.showModule(warningModule)
      }
    }
  })
  
  // // handle multiplt person detiction
  const [faceCount, setFaceCount] = useState()
  const [ cameraDetection, setCameraDetection ] = useState(true)
  useEffect(() => {
      if (!loading && faceCount === 0 && cameraDetection) {
        setTimerPause(true);
        setViolations(violations + 'No face detected,')
        setViolationQuestion(violationQuestion + questions[qusNum]?.num + ",")
        setWarningCount(warningCount + 1)
        setWarningMessage(['No Face Detected','Please look on the screen during exam.']);
        examFunctions.showModule(warningModule)
        setCameraDetection(false); setListning(false);
      } else if (faceCount > 1  && cameraDetection) {
        setTimerPause(true);
        setViolations(violations + 'Multiple person detected,')
        setViolationQuestion(violationQuestion + questions[qusNum]?.num + ",")
        setWarningCount(warningCount + 1)
        setWarningMessage(['More then one Face Detected','Please dont allow anyone to come in webcam view.']);
        examFunctions.showModule(warningModule)
        setCameraDetection(false) ;setListning(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[faceCount])

// check camera enabled
const [isCameraEnable, setisCameraEnable] = useState(true);
useEffect(() => {
  if(!loading && !isCameraEnable && cameraDetection){
    setTimerPause(true);
    setViolations(violations + 'Camera disabled,')
    setViolationQuestion(violationQuestion + questions[qusNum]?.num + ",")
    setWarningCount(warningCount + 1)
    setWarningType("cameraDisabled")
    setWarningMessage(['Camera Disabled','Please enable the web-cam to resume the exam.']);
    examFunctions.showModule(warningModule)
    setCameraDetection(false); setListning(false); 
  }else{
    setWarningType("cameraEnabled")
  }
},[isCameraEnable])

//handle cellPhone detection
const [cellPhoneDetection, setcellPhoneDetection] = useState(0)
useEffect(() => {
  if(!loading && cellPhoneDetection > 0){
    setTimerPause(true);
    setViolations(violations + 'Cellphone detected,')
    setViolationQuestion(violationQuestion + questions[qusNum]?.num + ",")
    setWarningCount(warningCount + 1)
    setWarningMessage(['Cell phone Detected','You cannot use mobile phones.']);
    examFunctions.showModule(warningModule)
    setCameraDetection(false) ;setListning(false);
  }
},[cellPhoneDetection])


  //handle voice detection
  const [voice, setVoice] = useState()
  const [listning, setListning] = useState(true)
  useEffect(()=>{
    // console.log(voice)
    if (!loading && voice?.length > 10){
      setTimerPause(true);
      setListning(false);
      setCameraDetection(false)
      setViolations(violations + 'Background voice detected,')
      setViolationQuestion(violationQuestion + questions[qusNum]?.num +',')
      // setWarningCount(warningCount + 1)
      setWarningType("voice")
      setWarningMessage(['Background Voice Detected','Please be silent during Assessment.']);
      examFunctions.showModule(warningModule)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[voice])

  // handle Terminate
  const handleTerminate = () => {
    // setViolations(violations + 'terminate')
    examFunctions.hideModule(warningModule);
    handleSubmit()
  }

  //next question button
  const handleNext = () => {
    const form = document.getElementById("examForm")
    let userAnswer = form[`q${qusNum+1}`]?.value
    setAnswerArr([...answerArr,userAnswer])

    if(qusNum < questions.length-1){
      setQusNum(qusNum + 1);
      form.reset();
      setTimer(questions[qusNum+1]?.time)
    }else{
      setTimerPause(true);
    }
  }

  // skip confirmation module
  const alertSkip = () => {
      setTimerPause(true);
			setConfirmationMessage(["Do you want to skip ?", "You will not be able to come back to this Question later."])
      setConfirmationButton(["Skip Question","Back to Question"])
      examFunctions.showModule(confirmationModule)
  }

  //confirm skip
  const confirmSkip = () => {
    if (qusNum + 1 >= questions?.length) {
      document.getElementById("last").classList.add("block");
      document.getElementById("last").classList.remove("hidden");
      document.getElementById("next").style.display = "none";
    }
    handleNext();
    cancelAlert();
	}

  //cancel module
  const cancelAlert = () => {
		examFunctions.hideModule(confirmationModule);
    setTimerPause(false);
	}

  //submit confirmation module
  const alertSubmit = () => {
    setTimerPause(true);
    if(qusNum !== questions.length-1){
      setConfirmationMessage([`${questions.length-qusNum-1} Questions still remaining !`, "Are you sure you want to skip the pending questions and submit the test ?"])
      setConfirmationButton(["Submit Anyways","Back to Test"])
    }else{
      setConfirmationMessage(["Are you sure ?", "You want to submit the test ?"])
      setConfirmationButton(["Submit","Back to Test"])
    }
    examFunctions.showModule(confirmationModule)
	}

  //confirm submit
  const confirmSubmit = () => {
    handleSubmit();
    cancelAlert();
    setTimerPause(true);
	}
  
  const totalQuestions = questions.length
  const resultData = examFunctions.checkAnswer(questions, answerArr)

  //submit exam
  const handleSubmit = () => {

    let examDateTime = examFunctions.examTimeDate(startTime)
    const date = examDateTime.examDate
    const timeTaken = examDateTime.timeTaken
    const attemptedQuestions = resultData.attempted
    const correctAnswers = resultData.correct
    const score = resultData.score

    const sheetData = `${dataSheetId}|${uid}|${candidateName}|${candidateEmail}|${examName}|${date}|${startTimeLocal}|${timeTaken}|${totalQuestions}|${attemptedQuestions}|${correctAnswers}|${warningCount}|${score}|${answerArr}|${violations}|${violationQuestion}|${questionArray}`;
    const postResultsApi = process.env.REACT_APP_POST_RESULTS_API
    
    let questionsTemplate = examFunctions.questionsTemplate(questions, answerArr)
    let emailSubject = `Technical Assessment Result - ${candidateName}`
    let emailMessage = emailBody.resultEmail(candidateName,candidateEmail,examName,totalQuestions,attemptedQuestions,correctAnswers,score,warningCount,questionsTemplate)
    let emailData = JSON.stringify([hrEmail,emailSubject,emailMessage])
    
    let mailApi = process.env.REACT_APP_EMAIL_API

    const nid = `nid_${Date.now()}`
    const notificationTime = new Date().toLocaleString("en-in",{hour: 'numeric' ,minute : 'numeric'})
    const notificationBody = `${candidateName} had submitted the Assessment Test`
    let notificationData = `${dataSheetId}|${nid}|${hrEmail}|${uid}|${new Date().toDateString()}|${notificationTime}|${notificationBody}`
    let addNotificationApi = process.env.REACT_APP_ADD_NOTIFICATION
        
		setTimeout(()=> {
      axios.post(postResultsApi,sheetData)
      axios.post(addNotificationApi,notificationData)
      axios.post(mailApi,emailData)
    },100)

		localStorage.removeItem('candidateDetails')
    localStorage.removeItem('refresh')
    examFunctions.showModule(submitModule)
  }

  // Time's Up
	const handleTimeUp = () => {
    if(qusNum !== questions.length-1){
      handleNext();
    } else {
      confirmSubmit();
    }
	}

  return (
    <div className='h-[65vh] select-none' onContextMenu={e => e.preventDefault()}>     
      <div className='fixed w-full z-10'>
			  <Topbar2 name={candidateName} email={candidateEmail}/>
		  </div>

      <div className='absolute top-2 left-[50%] translate-x-[-50%] z-50'>
        <Proctoring
          setFaceCount={setFaceCount}
          setVoice={setVoice}
          listning={listning}
          setcellPhoneDetection={setcellPhoneDetection}
          // webcamRef={webcamRef}
          setProctorLoading={setProctorLoading}
          setisCameraEnable={setisCameraEnable}
        />
      </div>

      {loading &&
        <div className='absolute w-full h-[100vh] top-0 left-0 z-30 flex justify-center items-center bg-black/[0.15] backdrop-blur-[4px]'>
          <ExamLoadingModule
            // loadingPercent={loadingPercent}
          />
        </div>
      }

      <div>
        <ExamTopPanel
          exam={examName}
          qusNum={qusNum}
          duration={timer}
          timeUp={handleTimeUp}
          timerPause={timerPause}
          alertSubmit={alertSubmit}
          loading={loading}
        />
      </div>      

      <div className='w-[95%] h-full mx-auto py-8 flex card'>
        <ExamQuestionPanel
          questions={questions}
          qusNum={qusNum}
          loading={loading}
        />
        <ExamOptionsPanel
          questions={questions}
          handleNext={handleNext}
          qusNum={qusNum}
          alertSkip={alertSkip}
          loading={loading}  
        />
      </div>

      <div id='confirmationModule' className='hidden moduleCover'>
        <ConfirmationModule
          confirmationMessage={confirmationMessage}
          confirmationButton={confirmationButton}
          cancelAlert={cancelAlert}
          confirmSkip={confirmSkip}          
          confirmSubmit={confirmSubmit}
          next={document.getElementById('next')}
        />
      </div>
      <div id='warningModule' className='hidden moduleCover'>
        <WarningModule
          type={warningType}
          warningCount={warningCount}
          warningMessage={warningMessage}
          handleTerminate={handleTerminate}
          hideModule={hideModule}
          hideLastWarning={hideLastWarning}
          maxViolations={maxViolations}
        />
      </div>
      
      <div id='submitModule' className='hidden w-full h-full fixed top-0 left-0 z-30 justify-center items-center'>
        <SubmitModule/>
      </div>

    </div>
  )
}

export default ExamPage
