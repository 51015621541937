import React from 'react'

const ExamQuestionPanel = ({questions, qusNum, loading}) => {
  return (
    <div className='w-[50%] h-[95%] px-12 border-r-2 border-[#CCC]'>
      { loading ? (
           <div className='w-full h-full flex justify-center items-center'>
              <p className='font-bold text-2xl text-[#bbb]'>
                Loading...
              </p>
           </div>          
        ) : (
        <div className='h-full'>
        <p className='font-medium text-[#333] mb-5'>
              Question 
              <span className='ml-2'>{qusNum+1}/{questions.length}</span>
        </p>
        <div className='w-full h-full overflow-y-auto scrollbar-none'>          
          <div className='text-[#666]'>
            <div>
              <p  style={{ whiteSpace: 'pre-line'}}>
                {questions[qusNum]?.questionStatment.split('<code>')[0]}
              </p>
              <p style={{ whiteSpace: 'pre', tabSize: 2}}>
                {questions[qusNum]?.questionStatment.split('<code>')[1]}
              </p>
            </div>
          </div>
          {(questions[qusNum]?.questionStatmentImage && questions[qusNum]?.questionStatmentImage !== ' ') &&
            <div className='w-full my-5 h-[75%] bg-gray-100 rounded-lg text-[#999] flex justify-center items-center relative'>
              {`Question${qusNum+1} - Image`}
              <img src={questions[qusNum]?.questionStatmentImage} alt='' className='absolute h-full w-full' />
            </div>
          }
        </div>
        </div>
      )}
      
    </div>
  )
}

export default ExamQuestionPanel
