import React from 'react'


import {Button} from '..'

const WarningModule = ({type, warningMessage, warningCount, handleTerminate, hideModule,hideLastWarning,maxViolations}) => {
  return (
    <div className="card flex flex-col justify-center items-center p-[50px] relative text-center w-[40%]">
        {type === 'refresh' ? (
            <>
                <p className='font-semibold text-lg mb-5'> Assessment Refreshed !</p>
                <p className='font-semibold text-xl mb-5 text-[#EA4335]'>Assessment Terminated</p>    
                <p className='text-[#919397] w-[75%]'>You cannot proceed with the assessment as you've violated the assessment rules.</p>

                <div className='flex mt-8 gap-5'>
                    <Button
                        text="Accept"
                        handleClick={handleTerminate}
                    />
                </div>
            </>
        ) : maxViolations === "NA" ? (
            <>
                {(type !== "lastWarning" && type !== 'voice') && 
                    <p className='font-semibold text-xl mb-5 text-[#FA921A]'>
                        Warning : {Math.floor(warningCount)}
                    </p>
                }                    
                
                <p className='font-semibold text-xl mb-5'>{warningMessage[0]}</p>
                <p className='text-[#919397] w-[75%]'>{warningMessage[1]}</p>

                <div className='flex mt-8 gap-5'>
                    {type !== 'devtools' && type !== 'cameraDisabled' &&
                        <Button
                            text="Back to Assessment"
                            handleClick={hideModule}
                        />
                    }
                </div>
            </>
        ) : warningCount < maxViolations ?
            (
                <>
                    <p className='font-semibold text-xl mb-5 text-[#FA921A]'>
                        {(type !== "lastWarning" && type !== 'voice') && `Warning : ${Math.floor(warningCount)} / ${maxViolations}`}
                    </p>
                    <p className='font-semibold text-xl mb-5'>{warningMessage[0]}</p>
                    <p className='text-[#919397] w-[75%]'>{warningMessage[1]}</p>

                    <div className='flex mt-8 gap-5'>
                        {type !== 'devtools' && type !== 'cameraDisabled' &&
                            <Button
                                text="Back to Assessment"
                                handleClick={type === "lastWarning" ? hideLastWarning : hideModule}
                            />
                        }
                    </div>
                </>
            ) : (
                // <>
                //     <p className='font-semibold text-xl mb-5 text-[#EA4335]'>Assessment Terminated</p>
                    
                //     <p className='text-[#919397] w-[75%]'>You cannot proceed with the assessment as you've violated the assessment rules.</p>

                //     <div className='flex mt-8 gap-5'>
                //         <Button
                //             text="Accept"
                //             handleClick={handleTerminate}
                //         />
                //     </div>
                // </>
                <>                   
                    <p className='font-semibold text-xl mb-5 text-[#EA4335]'>This is your last warning</p>
                    <p className='text-[#919397] w-[75%]'>If you violate the assessment rules again, Your assessment can be terminated.</p>
                    <div className='flex mt-8 gap-5'>
                        {type !== 'devtools' && type !== 'cameraDisabled' &&
                            <Button
                                text="Back to Assessment"
                                handleClick={type === "lastWarning" ? hideLastWarning : hideModule}
                            />
                        }
                    </div>
                </>
            )
        }

        
    </div>
  )
}

export default WarningModule